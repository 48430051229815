<script setup lang="ts">
import { ref, watch } from 'vue'
import { useCompetitions } from '/~/extensions/competitions/composables'
import BaseInput from '/~/components/base/input/base-input.vue'
import EwFilters from '/~/components/ewallet/controls/ew-filters.vue'
import EwSorting from '/~/components/ewallet/controls/ew-sorting.vue'
import { useProvider } from '/~/composables/provider'

const {
  searchQuery,
  sortValues,
  selectedSorting,
  searchGroup,
  isFiltersSelected,
  resetFilters,
} = useCompetitions()
const { isBupaTemplate } = useProvider()

const filters = ref<InstanceType<typeof EwFilters> | null>(null)

function onSort(value: string) {
  selectedSorting.value = value
}

watch(selectedSorting, () => {
  if (!isBupaTemplate.value) {
    filters.value?.close?.()
  }
})
</script>

<template>
  <div class="relative flex w-auto space-x-5 sm:flex-nowrap">
    <div class="relative hidden grow sm:block">
      <base-input
        v-model="searchQuery"
        :placeholder="`Search ${searchGroup}`"
        :icon-plain="true"
        look="rounded"
        icon="plain/search"
        :icon-size="14"
        clearable
        nolabel
      />
    </div>

    <ew-filters
      ref="filters"
      :is-filters-selected="isFiltersSelected"
      @clear="resetFilters"
    >
      <ew-sorting
        :value="selectedSorting"
        :sorting="sortValues"
        @input="onSort"
      />
    </ew-filters>
  </div>
</template>
